export function abbreviateNumbertoString(num: number): string {
  if (num >= 1e3 && num < 1e6) {
    return +(num / 1e3).toFixed(1) + "K";
  } else if (num >= 1e6 && num < 1e9) {
    return +(num / 1e6).toFixed(1) + "M";
  } else if (num >= 1e9 && num < 1e12) {
    return +(num / 1e9).toFixed(1) + "B";
  } else if (num >= 1e12) {
    return +(num / 1e12).toFixed(1) + "T";
  }

  return num.toString();
}

export function formatNumberWithCommas(num: any, digits: number = 0): string {
  if (!Number(num)) return "0";

  return Number(num).toLocaleString("en", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
}

export function formatFileSize(sizeInBytes: number): string {
  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let index = 0;
  let formattedSize = sizeInBytes;

  while (formattedSize >= 1024 && index < units.length - 1) {
    formattedSize /= 1024;
    index++;
  }

  return formattedSize.toFixed(2) + " " + units[index];
}

export function formatTimeWithAMPM(time: string) {
  const [hour, minute] = time.split(":");
  let nHour = parseInt(hour);
  const ampm = nHour >= 12 ? "PM" : "AM";
  nHour = nHour % 12;
  nHour = nHour ? nHour : 12;
  return nHour + ":" + minute + " " + ampm;
}

export function calculateTotalHours(startTime: string, endTime: string) {
  let startDate = new Date(`1970-01-01T${startTime}:00`);
  let endDate = new Date(`1970-01-01T${endTime}:00`);

  if (endDate < startDate) {
    endDate.setDate(startDate.getDate() + 1);
  }

  const diff = endDate.getTime() - startDate.getTime();
  return (diff / (1000 * 60 * 60)).toFixed(2);
}
