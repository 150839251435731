import { useEffect, useState } from "react";
import { Select } from "antd";
import Button from "./ui/button";
import InputWithoutLabel from "./ui/input-without-label";
import { CrossIcon } from "./icons/cross-icon";
import { JobType } from "src/lib/data-types";
import { SpinnerIcon } from "./icons/spinner-icon";
import Tasks from "./tasks";

const ActiveJobs = ({
  userId,
  serverMode,
  timeError,
  onSelect,
}: {
  userId: string;
  serverMode: number;
  timeError: boolean;
  onSelect: any;
}) => {
  const [data, setData] = useState<JobType[]>([]);
  const [searchText, setSearchText] = useState("");

  const [selectedJobIDs, setSelectedJobIDs] = useState<string[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<JobType[]>([]);

  const [isSearchingJobs, setSearchingJobs] = useState(false);

  const [taskHoursPerJob, setTaskHoursPerJob] = useState<
    { jobId: string; hours: { taskId: string; hour: string }[] }[]
  >([]);

  const removeDuplicates = (jobs: JobType[]): JobType[] => {
    const uniqueIds = new Set<string>();

    return jobs.filter((job) => {
      if (uniqueIds.has(job.id)) {
        return false; // Duplicate found
      } else {
        uniqueIds.add(job.id);
        return true; // Unique job
      }
    });
  };

  const searchJob = async () => {
    setSearchingJobs(true);

    const url = "https://hook.eu2.make.com/rh1uauj48h8ru8i4inox6bifnpqqg1py";
    const requestData = {
      user_id: userId,
      search_text: searchText,
      event: `job search - ${serverMode == 1 ? "internal" : "external"}`,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        setSearchingJobs(false);
        return;
        //throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setSearchingJobs(false);

      const result = await response.json();

      setData(removeDuplicates([...result, ...data]));
    } catch (error) {
      setSearchingJobs(false);
      console.error("Error searching jobs:", error);
    }
  };

  const onJobSelected = async (jobId: string) => {
    const url = "https://hook.eu2.make.com/r8706gccx7eytt18t3nivg95m8nkosm3";

    const payload = {
      job_id: jobId,
      event: "job selected",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log("Job selected webhook sent successfully");
      } else {
        console.error(
          "Error sending job selected webhook:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  useEffect(() => {
    if (userId.length > 0) return;

    setSearchText("");
    setData([]);
    setSelectedJobIDs([]);
    setSelectedJobs([]);
    setSearchingJobs(false);
    setTaskHoursPerJob([]);
  }, [userId]);

  useEffect(() => {
    if (userId.length == 0 || searchText.length == 0) {
      setSearchingJobs(false);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      setSearchingJobs(false);
      searchJob();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    if (!onSelect) return;

    onSelect(selectedJobs, taskHoursPerJob);
  }, [selectedJobs, taskHoursPerJob]);

  const handleSearch = (newValue: string) => {
    setSearchText(newValue);
  };

  const handleChange = (newValue: string[]) => {
    if (newValue.length < selectedJobIDs.length) {
      const deletedItems = selectedJobIDs.filter(
        (item) => !newValue.includes(item)
      );

      if (deletedItems.length > 0) {
        let updatedResult = [...taskHoursPerJob];
        updatedResult = updatedResult.filter(
          (item) => !deletedItems.includes(item.jobId)
        );

        console.log(updatedResult);
        setTaskHoursPerJob(updatedResult);
      }
    }

    //send webhook
    newValue.forEach((item) => {
      if (!selectedJobIDs.includes(item)) {
        onJobSelected(item);
      }
    });

    setSelectedJobIDs(newValue);
    setSelectedJobs(data.filter((item) => newValue.includes(item.id)));
  };

  const onTaskHoursChange = (
    jobId: string,
    hours: {
      taskId: string;
      hour: string;
    }[]
  ) => {
    let updatedResult = [...taskHoursPerJob];

    if (updatedResult.filter((item) => item.jobId == jobId).length == 0) {
      updatedResult = [...updatedResult, { jobId, hours }];
    } else {
      updatedResult = updatedResult.map((item) => {
        if (item.jobId == jobId) return { jobId, hours };
        else return item;
      });
    }

    setTaskHoursPerJob(updatedResult);
  };

  return (
    <div className="w-full flex flex-col">
      <label className="block cursor-pointer pb-2 text-[14px] font-medium text-secondary dark:text-light/70">
        Active Jobs
      </label>

      <div className="flex flex-row items-center justify-center gap-2">
        <Select
          showSearch
          mode="multiple"
          className="w-full"
          value={selectedJobIDs}
          placeholder={"Search for jobs..."}
          defaultActiveFirstOption={false}
          suffixIcon={null}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
          options={data.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
        {isSearchingJobs && (
          <SpinnerIcon className="h-auto w-5 animate-spin text-active" />
        )}
      </div>
      <div className="mt-4 w-full flex flex-col gap-4">
        {selectedJobs.map((item) => (
          <Tasks
            key={item.id}
            job={item}
            onChangeHours={onTaskHoursChange}
            timeError={timeError}
          />
        ))}
      </div>
    </div>
  );
};

export default ActiveJobs;
