import { useRef, useState } from "react";
import Button from "./ui/button";
import InputWithoutLabel from "./ui/input-without-label";
import { formatFileSize } from "src/lib/number";

const FileSelection = ({
  label,
  onFileChange,
  onDescrChange,
}: {
  label: string;
  onFileChange: any;
  onDescrChange: any;
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [strDescr, setDescr] = useState("");

  const onClick = () => {
    if (fileRef.current) {
      fileRef.current?.click();
    }
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    setFile(selectedFiles![0]);
    onFileChange(selectedFiles![0]);
  };

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-col gap-1">
        <div className="flex flex-row items-start gap-2 flex-shrink-0">
          <label className="block cursor-pointer text-[16px] font-bold text-secondary dark:text-light/70">
            {label}
          </label>
          <div className="flex flex-row items-start gap-2">
            <Button
              variant="text"
              className="bg-active hover:bg-blue-dark-400 text-white rounded-[8px] px-3 py-1 text-[12px]"
              onClick={onClick}
            >
              {"Choose File"}
            </Button>
            <input
              ref={fileRef}
              className="hidden"
              type="file"
              onChange={onHandleChange}
            />
          </div>
        </div>
        {file && (
          <label className="text-[14px] text-secondary font-gilroy">
            {`${file.name} (${formatFileSize(file.size)})`}
          </label>
        )}
      </div>
      <InputWithoutLabel
        placeholder="Enter description"
        className="w-full mt-2"
        value={strDescr}
        onChange={(e) => {
          setDescr(e.target.value);
          onDescrChange(e.target.value);
        }}
      />
    </div>
  );
};

export default FileSelection;
