import { useEffect, useState } from "react";
import { Select } from "antd";
import { JobType, TaskType } from "src/lib/data-types";
import { CrossIcon } from "./icons/cross-icon";
import Button from "./ui/button";
import InputWithoutLabel from "./ui/input-without-label";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";

const Tasks = ({
  job,
  timeError,
  onChangeHours,
}: {
  job: JobType;
  timeError: boolean;
  onChangeHours: any;
}) => {
  const isMounted = useIsMounted();

  const [isFetchingTasks, setFetchingTasks] = useState(false);

  const [allTasks, setAllTasks] = useState<TaskType[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<TaskType[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<TaskType[]>([]);

  const [taskHours, setTaskHours] = useState<
    { taskId: string; hour: string }[]
  >([]);

  const fetchTasks = async (jobId: string) => {
    setFetchingTasks(true);

    const url = "https://hook.eu2.make.com/r8706gccx7eytt18t3nivg95m8nkosm3"; // Your webhook URL

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ job_id: jobId }),
      });

      console.log("Webhook response received:", response);

      setFetchingTasks(false);

      if (!response.ok) {
        throw new Error(`Error fetching tasks: ${response.statusText}`);
      }

      const tasksPayload = await response.json();
      console.log("Parsed tasksPayload:", tasksPayload, job.name);

      if (Array.isArray(tasksPayload)) {
        console.log(
          "Valid tasksPayload array received:",
          tasksPayload,
          job.name
        );
        setAllTasks(tasksPayload);
        setFilteredTasks(tasksPayload);
      } else {
        console.error("Unexpected response structure:", tasksPayload, job.name);
      }
    } catch (error) {
      setFetchingTasks(false);

      console.error("Error:", error, job.name);
    }
  };

  useEffect(() => {
    if (!isMounted) return;

    fetchTasks(job.id);
  }, [isMounted, job]);

  useEffect(() => {
    if (taskHours.length == 0) return;

    onChangeHours(job.id, taskHours);
  }, [taskHours]);

  const removeDuplicates = (tasks: TaskType[]): TaskType[] => {
    const uniqueIds = new Set<string>();

    return tasks.filter((task) => {
      if (uniqueIds.has(task.taskId)) {
        return false; // Duplicate found
      } else {
        uniqueIds.add(task.taskId);
        return true; // Unique job
      }
    });
  };

  const handleTaskSearch = (newValue: string) => {
    if (newValue.length == 0) {
      setFilteredTasks(allTasks);
    } else {
      setFilteredTasks(
        allTasks.filter((item) =>
          item.name.toLowerCase().includes(newValue.toLowerCase())
        )
      );
    }
  };

  const handleTaskChange = (newValue: string) => {
    const filteredTemp = allTasks.filter((item) => item.taskId == newValue);
    const temp = [...selectedTasks, ...filteredTemp];
    setSelectedTasks(removeDuplicates(temp));
  };

  const viewFolder = () => {
    if (job["GDrive Project Folder URL"]) {
      window.open(job["GDrive Project Folder URL"], "_blank");
    }
  };

  const removeTask = (taskId: string) => {
    const result = selectedTasks.filter((item) => item.taskId != taskId);
    setSelectedTasks([...result]);

    const updatedResult = taskHours.filter((item) => item.taskId != taskId);
    setTaskHours([...updatedResult]);
  };

  const getTaskHours = (taskId: string) => {
    const filteredResult = taskHours.filter((item) => item.taskId == taskId);

    if (filteredResult.length == 0) return "";
    else return filteredResult[0].hour;
  };

  const updateTaskHours = (strNumber: string, taskId: string) => {
    let updatedResult = [...taskHours];

    if (updatedResult.filter((item) => item.taskId == taskId).length == 0) {
      updatedResult = [...updatedResult, { taskId: taskId, hour: strNumber }];
    } else {
      updatedResult = updatedResult.map((item) => {
        if (item.taskId == taskId)
          return { taskId: item.taskId, hour: strNumber };
        else return item;
      });
    }

    setTaskHours(updatedResult);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col sm:flex-row items-center justify-between pl-4">
        <label className="block cursor-pointer text-[14px] text-left w-full font-medium text-primary dark:text-light/70">
          {job.name}
        </label>
        <div className="flex flex-row items-center justify-end gap-2 w-full">
          <Select
            showSearch
            loading={isFetchingTasks}
            placeholder={"Search task"}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            className="w-full sm:max-w-[240px]"
            filterOption={false}
            value={""}
            onSearch={handleTaskSearch}
            onChange={handleTaskChange}
            notFoundContent={null}
            options={filteredTasks.map((item) => ({
              value: item.taskId,
              label: item.name,
            }))}
          />
          <Button
            variant="text"
            className={`bg-active hover:bg-blue-dark-400 text-white flex-shrink-0 !text-[14px] rounded-[8px] px-2 h-[32px] ${
              !job["GDrive Project Folder URL"] && "invisible"
            }`}
            onClick={viewFolder}
          >
            {"View Folder"}
          </Button>
        </div>
      </div>
      {selectedTasks.length > 0 && (
        <label className="pl-4 block cursor-pointer text-[14px] flex-shrink font-medium text-secondary dark:text-light/70">
          {"-Project Mangement"}
        </label>
      )}
      <div className="flex flex-col gap-2 mt-2 pl-8">
        {selectedTasks.map((item) => (
          <div
            key={item.taskId}
            className="flex flex-col gap-1 sm:flex-row items-center justify-between bg-gray-modern-800 rounded-[8px] px-4 py-2"
          >
            <label className="block cursor-pointer w-full text-left text-[14px] flex-shrink font-medium text-white dark:text-light/70">
              {item.name}
            </label>
            <div className="flex flex-row items-center justify-end gap-2 w-full">
              <InputWithoutLabel
                type="number"
                isSmall
                error={timeError}
                placeholder="Hours"
                className="w-full sm:max-w-[120px]"
                value={getTaskHours(item.taskId)}
                onChange={(e) => updateTaskHours(e.target.value, item.taskId)}
              />
              <Button
                variant="icon"
                className="bg-active hover:bg-blue-dark-400 text-white w-[32px] h-[32px] rounded-full flex-shrink-0"
                onClick={() => removeTask(item.taskId)}
              >
                <CrossIcon className="w-4 h-4 text-white" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tasks;
